<template>
  <v-container>
    <v-row>
      <v-col cols="2">
        <v-btn
          v-if="prevBtn"
          block
          color="primary"
          @click="previous"
          class="black--text"
        >
          <v-icon>
            mdi-chevron-left
          </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="1">
      </v-col>
      <v-col cols="9">
        <v-btn
          block
          color="primary"
          @click="next"
          :disabled="disabled"
          :loading="loading"
          class="black--text"
          id="navNext"
        >
          {{ text }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'NavButtons',
  props: {
    text: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    prevBtn: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    previous() {
      this.$emit('previous');
    },
    next() {
      this.$emit('next');
    },
  },
};
</script>
